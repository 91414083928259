<template>
  <v-container class="tw-py-2 md:tw-py-5">
    <div>
      <div class="tw-px-0 md:tw-px-6 tw-mt-2">
        <div class="tw-px-0 md:tw-px-6">
          <p>
            Submit your Bid on the Vehicle for confirmation of the estimated
            prices of vehicle parts before repairs and quantity required, grade
            and quality of the parts as well as their prices.
          </p>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Client Details
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-0 tw-gap-8"
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Assessment No.</label>
                <span class="c-gray-text">{{ bookingId }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Claim No.</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.claimNumber')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Insured Name</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'clientInfo.name')
                }}</span>
              </div>
            </div>
            <div>
              <div
                v-if="scanObject(booking, 'createdAt')"
                class="tw-grid tw-grid-cols-2 tw-flex tw-items-center"
              >
                <label class="c-gray-text tw-font-bold">Date Created</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'createdAt') | formatToHuman
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Policy No.</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'policyInfo.policyNumber')
                }}</span>
              </div>
            </div>
          </div>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Vehicle Details
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-0 tw-gap-8"
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Registration No.</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'vehicleInfo.registrationNumber')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Vehicle Color</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.color')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Engine No.</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.engineNumber')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Make</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.make')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">YOM</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.yearOfManufacture')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Chassis No.</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.chassisNumber')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Model</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.model')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Vehicle Type</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.bodyType')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <label class="c-gray-text tw-font-bold">Mileage</label>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.mileage')
                }}</span>
              </div>
            </div>
          </div>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Assessment Report
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <p>
            From the garage bid reports on the Vehicle please confirm the
            estimated prices of vehicle parts before repairs and quantity
            required, grade and quality of the parts as well as their prices.'
          </p>
          <h2 class="my-2">Estimate Report:</h2>
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th scope="col" class="text-left">Parts/Services</th>
                    <th scope="col" class="text-left">Type</th>
                    <th scope="col" class="text-left">Quantity</th>
                    <th scope="col" class="text-left">Unit Price</th>
                    <th scope="col" class="text-left">Total</th>
                    <th scope="col" class="text-left">VAT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in parts" :key="item.name">
                    <td>{{ item.part }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.unitPrice | formatCurrency }}</td>
                    <td>{{ getTotal(item) | formatCurrency }}</td>
                    <td>
                      <v-switch v-model="item.vat" disabled></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <hr />
            <div
              class="
                tw-flex tw-justify-start
                md:tw-justify-end
                tw-py-2
                md:tw-py-4
              "
            >
              <div class="tw-w-full md:tw-w-1/3">
                <div class="tw-grid tw-grid-cols-2 c-gray-text">
                  <h6>Net Total</h6>
                  <h6>Ksh. {{ netTotal | formatCurrency }}</h6>
                </div>
                <div class="tw-grid tw-grid-cols-2 c-gray-text">
                  <h6>VAT 16%</h6>
                  <h6>Ksh. {{ vatTotal | formatCurrency }}</h6>
                </div>
                <div
                  v-if="contributionTotal > 0"
                  class="tw-grid tw-grid-cols-2 c-gray-text"
                >
                  <h6>Client contribution</h6>
                  <h6>(Ksh. {{ contributionTotal | formatCurrency }})</h6>
                </div>
                <div
                  class="
                    tw-grid tw-grid-cols-2
                    c-gray-text
                    tw-text-xl
                    md:tw-text-2xl
                  "
                >
                  <h6>TOTAL</h6>
                  <h6>Ksh. {{ grandTotal | formatCurrency }}</h6>
                </div>
              </div>
            </div>
            <hr />
            <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
              Additional Information
            </h2>
            <hr class="tw-mb-2 md:tw-mb-5" />
            <p
              v-html="scanObject(booking, 'biddingForm.additionalInformation')"
            ></p>
          </div>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Damaged Vehicle Photos
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <p>
            Attach and submit the photos of the damaged vehicle to show where
            necessarily repairs should be done.
          </p>

          <images :photos="photos" />

<div v-if="scanObject(booking, 'biddingForm.confidentialInformationFromExternalAssessor')">
  <hr />
  <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
    Assessor's Remark:
  </h2>
  <hr class="tw-mb-2 md:tw-mb-5" />
  <p
      v-html="scanObject(booking, 'biddingForm.confidentialInformationFromExternalAssessor')"
  ></p>
</div>
          <hr />

          <div
            class="
              tw-grid tw-grid-cols-1
              md:tw-grid-cols-2
              tw-gap-2
              md:tw-gap-10
            "
          >
            <div class="py-6">
              <label>Assessor's Signature</label>
              <v-text-field
                placeholder="Full Name"
                :rules="[required('Full Name')]"
                disabled
                :value="scanObject(booking, 'biddingForm.assessorName')"
              ></v-text-field>
            </div>
            <div v-if="isGarage" class="tw-mt-2 md:tw-mt-0">
              <label class="c-blue-text">Repairer's Signature</label>
              <v-text-field
                placeholder="Full Name"
                :rules="[required('Full Name')]"
                disabled
                :value="scanObject(booking, 'garage.contact_person')"
              ></v-text-field>
            </div>
            <div v-if="isGarage" class="tw-mt-2 md:tw-mt-0">
              <p>
                As the claims committee do you agree to the contents of this
                document as Captured by the service provider? If you do check
                below to authorize repairs.
              </p>
              <v-checkbox label="Authorize Repair"></v-checkbox>
            </div>
          </div>
        </div>
        <div v-if="isGarage" class="tw-flex tw-justify-center">
          <div
            class="
              tw-w-2/3
              md:tw-w-1/3
              tw-py-4
              md:tw-py-10
              tw-space-x-2 tw-flex tw-justify-center
            "
          >
            <v-btn color="warning" :loading="submitting" :disabled="!isValid"
              >Finish</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { formatCurrency } from '@/utils/helpers'
import { VAT } from '@/utils/const'
import Validations from '@/utils/Validations'

import { formatToHuman } from '@/utils/time'
import Images from '@/components/Images'
import biddingFormMixin from '@/mixins/biddingFormMixin'

export default {
  name: 'AssessmentReport',
  components: { Images },
  props: {
    booking: {
      required: true,
      type: Object,
    },
    isGarage: {
      type: Boolean,
      default: true,
    },
  },
  data(vm) {
    return {
      vatRate: VAT,
      ...Validations,
      submitting: false,
      isValid: false,
      bookingId: vm.$route.params.bookingId || vm.scanObject(vm, 'booking.id'),
    }
  },
  mixins: [biddingFormMixin],
  computed: {
    parts() {
      return this.scanObject(this.booking, 'biddingForm.repairs', [])
    },
    photos() {
      return this.scanObject(this.booking, 'biddingForm.photos', [])
    },
  },
  filters: {
    formatCurrency,
    formatToHuman,
  },
}
</script>
